import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import ImageCarousel from '../ListingPage/ImageCarousel/ImageCarousel';
import { Heading, ListingCard } from '../../components';

import css from './LandingPage.module.css';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export function insertItem(array, item, index) {
  array.splice(index, 0, item);
  return array;
}

const SectionUser = props => {
  const { sectionId } = props;
  return <section id={sectionId}></section>;
};

const SectionLatestProducts = props => {
  const { sectionId, listings } = props;
  return (
    <section id={sectionId} className={css.latestProductsSection}>
      <h3>New Listings</h3>
      <div className={css.listingsContainer}>
        <ul className={css.listings}>
          {listings.map(l => (
            <li className={css.listing} key={l.id.uuid}>
              <ListingCard listing={l} showAuthorInfo={false} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export const LandingPageComponent = props => {
  const { pageAssetsData, listings, inProgress, error } = props;
  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  const sectionUserName = {
    sectionId: 'test-section',
    sectionType: 'customUser',
  };

  const sectionPopularCategories = {
    sectionId: 'popular-categories',
    sectionType: 'popularCategories',
  };

  const latestProducts = {
    sectionId: 'latest-products',
    sectionType: 'latestProducts',
    listings: listings,
  };

  let customSections = pageData ? [sectionUserName, ...pageData.sections] : pageData?.sections;

  if (pageData?.sections) {
    // customSections = insertItem(customSections, sectionPopularCategories, 3);
    customSections = insertItem(customSections, latestProducts, 4);
  }

  return (
    <>
      <PageBuilder
        pageAssetsData={{
          ...pageData,
          sections: customSections,
        }}
        options={{
          sectionComponents: {
            customUser: { component: SectionUser },
            // popularCategories: { component: popularCategories },
            latestProducts: { component: SectionLatestProducts },
          },
        }}
        inProgress={inProgress}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
      />
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  listingRefs: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, listingRefs, error } = state.hostedAssets || {};
  if (listingRefs && listingRefs.length > 0) {
    const listings = getMarketplaceEntities(state, listingRefs);
    return { pageAssetsData, listings, inProgress, error };
  }

  return { pageAssetsData, listings: [], inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
